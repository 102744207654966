import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { career_hreo } from '../../images/index';
import style from './index.module.less';
import classNames from "classnames"
import { email_icon } from '@/images';


const Img = styled.img`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //filter: brightness(60%);
`

const Spacer = ({ h = 0 }) => <div style={{ height: h }} />
const HeroWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
  text-align: center;
  padding:0 20px;
`

const Words = styled.div`
  position: relative;
  font-size: 64px;
  color: white;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`

const BigTitle = styled.div`
  font-size: 45px;
  font-weight: bold;
  color: #000000;
  line-height: 36px;
  margin: 0 auto;
  text-align: center;
`

const SmallTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  & > strong {
    font-weight: bold;
    font-size: 24px;
    margin-right: 3px;
  }
`

const CenterDiv = styled.div`
  max-width: 600px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  line-height: 19px;
  margin: 0 auto;
  text-align: center;
`

const LeftDiv = styled.div`
  max-width: 625px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  line-height: 21px;
  margin: 0 auto;
  text-align: left;
`

const LeftLi = styled.div`
  max-width: 520px;
  padding: 0 20px 0 30px;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  line-height: 21px;
  margin: 5px auto 0;
  text-align: left;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 20px;
    top: 9px;
    width: 4px;
    height: 4px;
    background: #d2d2d2;
    border-radius: 99em;
  }
`

const Index = () => {


  const toSchool = () => {
    window.open('https://xreal.jobs.feishu.cn/campus');
  }

  const toSocirty = () => {
    window.open('https://xreal.jobs.feishu.cn/talent');
  }
  return (
    <Layout menuBlack={false}>
      <SEO page="Career" />
      <HeroWrapper >
        <img
          src={career_hreo}
          style={{ height: "101%" }}
          css={
            `
            position: absolute !important;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            `
          }
          alt="XREAL career"
        />
        <div className={style.career_main_content}>
          <Words>
            Come join us and bravely challenge yourself!

          </Words>
          <div className={style.career_contact_container}>
            <div className={style.careerBtnContainer}>
              <div
                className={`${classNames("whiteButton", "font_16_B")} ${style.career_careerBtn}`}
                onClick={toSchool}
              >
                Campus Recruitment
              </div>
              <div className={style.career_email}>
                <img src={email_icon} alt='email' style={{ marginRight: 10, marginBottom: 2 }} />
                campus@xreal.com
              </div>
            </div>
            <div className={style.careerBtnContainer}>
              <div
                className={`${classNames("whiteButton", "font_16_B")} ${style.career_careerBtn}`}
                onClick={toSocirty}
              >
                Social Recruitment
              </div>
              <div className={style.career_email}>
                <img src={email_icon} alt='email' style={{ marginRight: 10, marginBottom: 2 }} />
                talent@xreal.com
              </div>
            </div>
          </div>
        </div>
      </HeroWrapper>

    </Layout>
  )
}

export default Index
